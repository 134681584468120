
import { Component, Vue } from "vue-property-decorator";
import Formulario from "@/components/Contacto/Formulario.vue";

@Component({
  components: {
    Formulario
  },
  meta: {
    // sets document title
    title: "Formulario de Consultas - Municipalidad de Maipú",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content:
          "Si necesitas información o tienes alguna duda, por favor completa el formulario y nos contactaremos contigo."
      },
      keywords: { name: "keywords", content: "Maipú" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class ContactoView extends Vue {
  private mounted() {
    document.title = "Formulario de Consultas - Municipalidad de Maipú";
  }
}
