
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface DataContacto {
  rut: string;
  nombre: string;
  email: string;
  telefono: string;
  direccion: string;
  comuna: string;
  mensaje: string;
  asunto: string;
}
interface SelectOption {
  value: string | any;
  label: string;
}

@Component
export default class Formulario extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private rutContacto = "";
  private infoContacto: DataContacto = {
    rut: "",
    nombre: "",
    email: "",
    telefono: "",
    direccion: "",
    comuna: "Maipú",
    mensaje: "",
    asunto: ""
  };
  private cityList = [
    { value: "Aisén", label: "Aisén" },
    { value: "Algarrobo", label: "Algarrobo" },
    { value: "Alhué", label: "Alhué" },
    { value: "Alto Biobío", label: "Alto Biobío" },
    { value: "Alto del Carmen", label: "Alto del Carmen" },
    { value: "Alto Hospicio", label: "Alto Hospicio" },
    { value: "Ancud", label: "Ancud" },
    { value: "Andacollo", label: "Andacollo" },
    { value: "Angol", label: "Angol" },
    { value: "Antártica", label: "Antártica" },
    { value: "Antofagasta", label: "Antofagasta" },
    { value: "Antuco", label: "Antuco" },
    { value: "Arauco", label: "Arauco" },
    { value: "Arica", label: "Arica" },
    { value: "Buin", label: "Buin" },
    { value: "Bulnes", label: "Bulnes" },
    { value: "Cabildo", label: "Cabildo" },
    { value: "Cabo de Hornos", label: "Cabo de Hornos" },
    { value: "Cabrero", label: "Cabrero" },
    { value: "Calama", label: "Calama" },
    { value: "Calbuco", label: "Calbuco" },
    { value: "Caldera", label: "Caldera" },
    { value: "Calera de Tango", label: "Calera de Tango" },
    { value: "Calle Larga", label: "Calle Larga" },
    { value: "Camarones", label: "Camarones" },
    { value: "Camiña", label: "Camiña" },
    { value: "Canela", label: "Canela" },
    { value: "Cañete", label: "Cañete" },
    { value: "Carahue", label: "Carahue" },
    { value: "Cartagena", label: "Cartagena" },
    { value: "Casablanca", label: "Casablanca" },
    { value: "Castro", label: "Castro" },
    { value: "Catemu", label: "Catemu" },
    { value: "Cauquenes", label: "Cauquenes" },
    { value: "Cerrillos", label: "Cerrillos" },
    { value: "Cerro Navia", label: "Cerro Navia" },
    { value: "Chaitén", label: "Chaitén" },
    { value: "Chañaral", label: "Chañaral" },
    { value: "Chanco", label: "Chanco" },
    { value: "Chépica", label: "Chépica" },
    { value: "Chiguayante", label: "Chiguayante" },
    { value: "Chile Chico", label: "Chile Chico" },
    { value: "Chillán", label: "Chillán" },
    { value: "Chillán Viejo", label: "Chillán Viejo" },
    { value: "Chimbarongo", label: "Chimbarongo" },
    { value: "Cholchol", label: "Cholchol" },
    { value: "Chonchi", label: "Chonchi" },
    { value: "Cisnes", label: "Cisnes" },
    { value: "Cobquecura", label: "Cobquecura" },
    { value: "Cochamó", label: "Cochamó" },
    { value: "Cochrane", label: "Cochrane" },
    { value: "Codegua", label: "Codegua" },
    { value: "Coelemu", label: "Coelemu" },
    { value: "Coihaique", label: "Coihaique" },
    { value: "Coihueco", label: "Coihueco" },
    { value: "Coínco", label: "Coínco" },
    { value: "Colbún", label: "Colbún" },
    { value: "Colchane", label: "Colchane" },
    { value: "Colina", label: "Colina" },
    { value: "Collipulli", label: "Collipulli" },
    { value: "Coltauco", label: "Coltauco" },
    { value: "Combarbalá", label: "Combarbalá" },
    { value: "Concepción", label: "Concepción" },
    { value: "Conchalí", label: "Conchalí" },
    { value: "Concón", label: "Concón" },
    { value: "Constitución", label: "Constitución" },
    { value: "Contulmo", label: "Contulmo" },
    { value: "Copiapó", label: "Copiapó" },
    { value: "Coquimbo", label: "Coquimbo" },
    { value: "Coronel", label: "Coronel" },
    { value: "Corral", label: "Corral" },
    { value: "Cunco", label: "Cunco" },
    { value: "Curacautín", label: "Curacautín" },
    { value: "Curacaví", label: "Curacaví" },
    { value: "Curaco de Vélez", label: "Curaco de Vélez" },
    { value: "Curanilahue", label: "Curanilahue" },
    { value: "Curarrehue", label: "Curarrehue" },
    { value: "Curepto", label: "Curepto" },
    { value: "Curicó", label: "Curicó" },
    { value: "Dalcahue", label: "Dalcahue" },
    { value: "Diego de Almagro", label: "Diego de Almagro" },
    { value: "Doñihue", label: "Doñihue" },
    { value: "El Bosque", label: "El Bosque" },
    { value: "El Carmen", label: "El Carmen" },
    { value: "El Monte", label: "El Monte" },
    { value: "El Quisco", label: "El Quisco" },
    { value: "El Tabo", label: "El Tabo" },
    { value: "Empedrado", label: "Empedrado" },
    { value: "Ercilla", label: "Ercilla" },
    { value: "Estación Central", label: "Estación Central" },
    { value: "Florida", label: "Florida" },
    { value: "Freire", label: "Freire" },
    { value: "Freirina", label: "Freirina" },
    { value: "Fresia", label: "Fresia" },
    { value: "Frutillar", label: "Frutillar" },
    { value: "Futaleufú", label: "Futaleufú" },
    { value: "Futrono", label: "Futrono" },
    { value: "Galvarino", label: "Galvarino" },
    { value: "General Lagos", label: "General Lagos" },
    { value: "Gorbea", label: "Gorbea" },
    { value: "Graneros", label: "Graneros" },
    { value: "Guaitecas", label: "Guaitecas" },
    { value: "Hijuelas", label: "Hijuelas" },
    { value: "Hualaihué", label: "Hualaihué" },
    { value: "Hualañé", label: "Hualañé" },
    { value: "Hualpén", label: "Hualpén" },
    { value: "Hualqui", label: "Hualqui" },
    { value: "Huara", label: "Huara" },
    { value: "Huasco", label: "Huasco" },
    { value: "Huechuraba", label: "Huechuraba" },
    { value: "Illapel", label: "Illapel" },
    { value: "Independencia", label: "Independencia" },
    { value: "Iquique", label: "Iquique" },
    { value: "Isla de Maipo", label: "Isla de Maipo" },
    { value: "Isla de Pascua", label: "Isla de Pascua" },
    { value: "Juan Fernández", label: "Juan Fernández" },
    { value: "La Calera", label: "La Calera" },
    { value: "La Cisterna", label: "La Cisterna" },
    { value: "La Cruz", label: "La Cruz" },
    { value: "La Estrella", label: "La Estrella" },
    { value: "La Florida", label: "La Florida" },
    { value: "La Granja", label: "La Granja" },
    { value: "La Higuera", label: "La Higuera" },
    { value: "La Ligua", label: "La Ligua" },
    { value: "La Pintana", label: "La Pintana" },
    { value: "La Reina", label: "La Reina" },
    { value: "La Serena", label: "La Serena" },
    { value: "La Unión", label: "La Unión" },
    { value: "Lago Ranco", label: "Lago Ranco" },
    { value: "Lago Verde", label: "Lago Verde" },
    { value: "Laguna Blanca", label: "Laguna Blanca" },
    { value: "Laja", label: "Laja" },
    { value: "Lampa", label: "Lampa" },
    { value: "Lanco", label: "Lanco" },
    { value: "Las Cabras", label: "Las Cabras" },
    { value: "Las Condes", label: "Las Condes" },
    { value: "Lautaro", label: "Lautaro" },
    { value: "Lebu", label: "Lebu" },
    { value: "Licantén", label: "Licantén" },
    { value: "Limache", label: "Limache" },
    { value: "Linares", label: "Linares" },
    { value: "Litueche", label: "Litueche" },
    { value: "Llaillay", label: "Llaillay" },
    { value: "Llanquihue", label: "Llanquihue" },
    { value: "Lo Barnechea", label: "Lo Barnechea" },
    { value: "Lo Espejo", label: "Lo Espejo" },
    { value: "Lo Prado", label: "Lo Prado" },
    { value: "Lolol", label: "Lolol" },
    { value: "Loncoche", label: "Loncoche" },
    { value: "Longaví", label: "Longaví" },
    { value: "Lonquimay", label: "Lonquimay" },
    { value: "Los Álamos", label: "Los Álamos" },
    { value: "Los Andes", label: "Los Andes" },
    { value: "Los Ángeles", label: "Los Ángeles" },
    { value: "Los Lagos", label: "Los Lagos" },
    { value: "Los Muermos", label: "Los Muermos" },
    { value: "Los Sauces", label: "Los Sauces" },
    { value: "Los Vilos", label: "Los Vilos" },
    { value: "Lota", label: "Lota" },
    { value: "Lumaco", label: "Lumaco" },
    { value: "Machalí", label: "Machalí" },
    { value: "Macul", label: "Macul" },
    { value: "Máfil", label: "Máfil" },
    { value: "Maipú", label: "Maipú" },
    { value: "Malloa", label: "Malloa" },
    { value: "Marchihue", label: "Marchihue" },
    { value: "María Elena", label: "María Elena" },
    { value: "María Pinto", label: "María Pinto" },
    { value: "Mariquina", label: "Mariquina" },
    { value: "Maule", label: "Maule" },
    { value: "Maullín", label: "Maullín" },
    { value: "Mejillones", label: "Mejillones" },
    { value: "Melipeuco", label: "Melipeuco" },
    { value: "Melipilla", label: "Melipilla" },
    { value: "Molina", label: "Molina" },
    { value: "Monte Patria", label: "Monte Patria" },
    { value: "Mostazal", label: "Mostazal" },
    { value: "Mulchén", label: "Mulchén" },
    { value: "Nacimiento", label: "Nacimiento" },
    { value: "Nancagua", label: "Nancagua" },
    { value: "Natales", label: "Natales" },
    { value: "Navidad", label: "Navidad" },
    { value: "Negrete", label: "Negrete" },
    { value: "Ninhue", label: "Ninhue" },
    { value: "Ñiquen", label: "Ñiquen" },
    { value: "Nogales", label: "Nogales" },
    { value: "Nueva Imperial", label: "Nueva Imperial" },
    { value: "Ñuñoa", label: "Ñuñoa" },
    { value: "O'higgins", label: "O'higgins" },
    { value: "Olivar", label: "Olivar" },
    { value: "Ollague", label: "Ollague" },
    { value: "Olmué", label: "Olmué" },
    { value: "Osorno", label: "Osorno" },
    { value: "Ovalle", label: "Ovalle" },
    { value: "Padre Hurtado", label: "Padre Hurtado" },
    { value: "Padre Las Casas", label: "Padre Las Casas" },
    { value: "Paihuaco", label: "Paihuaco" },
    { value: "Paillaco", label: "Paillaco" },
    { value: "Paine", label: "Paine" },
    { value: "Palena", label: "Palena" },
    { value: "Palmilla", label: "Palmilla" },
    { value: "Panguipulli", label: "Panguipulli" },
    { value: "Panquehue", label: "Panquehue" },
    { value: "Papudo", label: "Papudo" },
    { value: "Parral", label: "Parral" },
    { value: "Pedro Aguirre Cerda", label: "Pedro Aguirre Cerda" },
    { value: "Pelarco", label: "Pelarco" },
    { value: "Pelluhue", label: "Pelluhue" },
    { value: "Pemuco", label: "Pemuco" },
    { value: "Peñaflor", label: "Peñaflor" },
    { value: "Peñalolén", label: "Peñalolén" },
    { value: "Pencahue", label: "Pencahue" },
    { value: "Penco", label: "Penco" },
    { value: "Peralillo", label: "Peralillo" },
    { value: "Peredones", label: "Peredones" },
    { value: "Perquenco", label: "Perquenco" },
    { value: "Petorca", label: "Petorca" },
    { value: "Peumo", label: "Peumo" },
    { value: "Pica", label: "Pica" },
    { value: "Pichidegua", label: "Pichidegua" },
    { value: "Pichilemu", label: "Pichilemu" },
    { value: "Pinto", label: "Pinto" },
    { value: "Pirque", label: "Pirque" },
    { value: "Pitrufquén", label: "Pitrufquén" },
    { value: "Placilla", label: "Placilla" },
    { value: "Portezuelo", label: "Portezuelo" },
    { value: "Porvenir", label: "Porvenir" },
    { value: "Pozo Almonte", label: "Pozo Almonte" },
    { value: "Primavera", label: "Primavera" },
    { value: "Providencia", label: "Providencia" },
    { value: "Puchuncaví", label: "Puchuncaví" },
    { value: "Pucón", label: "Pucón" },
    { value: "Pudahuel", label: "Pudahuel" },
    { value: "Puente Alto", label: "Puente Alto" },
    { value: "Puero Octay", label: "Puero Octay" },
    { value: "Puerto Montt", label: "Puerto Montt" },
    { value: "Puerto Varas", label: "Puerto Varas" },
    { value: "Pumanque", label: "Pumanque" },
    { value: "Punitaqui", label: "Punitaqui" },
    { value: "Punta Arenas", label: "Punta Arenas" },
    { value: "Puqueldón", label: "Puqueldón" },
    { value: "Purén", label: "Purén" },
    { value: "Purranque", label: "Purranque" },
    { value: "Putaendo", label: "Putaendo" },
    { value: "Putre", label: "Putre" },
    { value: "Puyehue", label: "Puyehue" },
    { value: "Queilén", label: "Queilén" },
    { value: "Quellón", label: "Quellón" },
    { value: "Quemchi", label: "Quemchi" },
    { value: "Quilaco", label: "Quilaco" },
    { value: "Quilicura", label: "Quilicura" },
    { value: "Quilleco", label: "Quilleco" },
    { value: "Quillón", label: "Quillón" },
    { value: "Quillota", label: "Quillota" },
    { value: "Quilpué", label: "Quilpué" },
    { value: "Quinchao", label: "Quinchao" },
    { value: "Quinta de Tilcoco", label: "Quinta de Tilcoco" },
    { value: "Quinta Normal", label: "Quinta Normal" },
    { value: "Quintero", label: "Quintero" },
    { value: "Quirihue", label: "Quirihue" },
    { value: "Rancagua", label: "Rancagua" },
    { value: "Ránquil", label: "Ránquil" },
    { value: "Rauco", label: "Rauco" },
    { value: "Recoleta", label: "Recoleta" },
    { value: "Renaico", label: "Renaico" },
    { value: "Renca", label: "Renca" },
    { value: "Rengo", label: "Rengo" },
    { value: "Requínoa", label: "Requínoa" },
    { value: "Retiro", label: "Retiro" },
    { value: "Rinconada", label: "Rinconada" },
    { value: "Río Bueno", label: "Río Bueno" },
    { value: "Río Claro", label: "Río Claro" },
    { value: "Río Hurtado", label: "Río Hurtado" },
    { value: "Río Ibáñez", label: "Río Ibáñez" },
    { value: "Río Negro", label: "Río Negro" },
    { value: "Río Verde", label: "Río Verde" },
    { value: "Romeral", label: "Romeral" },
    { value: "Saavedra", label: "Saavedra" },
    { value: "Sagrada Familia", label: "Sagrada Familia" },
    { value: "Salamanca", label: "Salamanca" },
    { value: "San Antonio", label: "San Antonio" },
    { value: "San Bernardo", label: "San Bernardo" },
    { value: "San Carlos", label: "San Carlos" },
    { value: "San Clemente", label: "San Clemente" },
    { value: "San Esteban", label: "San Esteban" },
    { value: "San Fabián", label: "San Fabián" },
    { value: "San Felipe", label: "San Felipe" },
    { value: "San Fernando", label: "San Fernando" },
    { value: "San Gregorio", label: "San Gregorio" },
    { value: "San Ignacio", label: "San Ignacio" },
    { value: "San Javier", label: "San Javier" },
    { value: "San Joaquín", label: "San Joaquín" },
    { value: "San José de Maipo", label: "San José de Maipo" },
    { value: "San Juan de la Costa", label: "San Juan de la Costa" },
    { value: "San Miguel", label: "San Miguel" },
    { value: "San Nicolás", label: "San Nicolás" },
    { value: "San Pablo", label: "San Pablo" },
    { value: "San Pedro", label: "San Pedro" },
    { value: "San Pedro de Atacama", label: "San Pedro de Atacama" },
    { value: "San Pedro de La Paz", label: "San Pedro de La Paz" },
    { value: "San Rafael", label: "San Rafael" },
    { value: "San Ramón", label: "San Ramón" },
    { value: "San Rosendo", label: "San Rosendo" },
    {
      value: "San Vicente de Tagua Tagua",
      label: "San Vicente de Tagua Tagua"
    },
    { value: "Santa Bárbara", label: "Santa Bárbara" },
    { value: "Santa Cruz", label: "Santa Cruz" },
    { value: "Santa Juana", label: "Santa Juana" },
    { value: "Santa María", label: "Santa María" },
    { value: "Santiago", label: "Santiago" },
    { value: "Santo Domingo", label: "Santo Domingo" },
    { value: "Sierra Gorda", label: "Sierra Gorda" },
    { value: "Talagante", label: "Talagante" },
    { value: "Talca", label: "Talca" },
    { value: "Talcahuano", label: "Talcahuano" },
    { value: "Taltal", label: "Taltal" },
    { value: "Temuco", label: "Temuco" },
    { value: "Teno", label: "Teno" },
    { value: "Teodoro Schmidt", label: "Teodoro Schmidt" },
    { value: "Tierra Amarilla", label: "Tierra Amarilla" },
    { value: "Tiltil", label: "Tiltil" },
    { value: "Timaukel", label: "Timaukel" },
    { value: "Tirúa", label: "Tirúa" },
    { value: "Tocopilla", label: "Tocopilla" },
    { value: "Toltén", label: "Toltén" },
    { value: "Tomé", label: "Tomé" },
    { value: "Torres del Paine", label: "Torres del Paine" },
    { value: "Tortel", label: "Tortel" },
    { value: "Traiguén", label: "Traiguén" },
    { value: "Treguaco", label: "Treguaco" },
    { value: "Tucapel", label: "Tucapel" },
    { value: "Valdivia", label: "Valdivia" },
    { value: "Vallenar", label: "Vallenar" },
    { value: "Valparaíso", label: "Valparaíso" },
    { value: "Vichuquén", label: "Vichuquén" },
    { value: "Victoria", label: "Victoria" },
    { value: "Vicuña", label: "Vicuña" },
    { value: "Vilcún", label: "Vilcún" },
    { value: "Villa Alegre", label: "Villa Alegre" },
    { value: "Villa Alemana", label: "Villa Alemana" },
    { value: "Villarrica", label: "Villarrica" },
    { value: "Viña del Mar", label: "Viña del Mar" },
    { value: "Vitacura", label: "Vitacura" },
    { value: "Yerbas Buenas", label: "Yerbas Buenas" },
    { value: "Yumbel", label: "Yumbel" },
    { value: "Yungay", label: "Yungay" },
    { value: "Zapallar", label: "Zapallar" }
  ];

  private comunaOptions: SelectOption[] = [];
  private dataComuna: SelectOption = {
    value: "Maipú",
    label: "Maipú"
  };

  @Watch("rutContacto")
  private formatRutVecino(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.infoContacto.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutContacto = `${rut}-${dv}`;
    } else {
      this.rutContacto = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.infoContacto.comuna = newValue.label;
  }
  private enviarCorreo() {
    if (
      this.infoContacto.rut == "" ||
      this.validateRut(this.infoContacto.rut) == false
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.nombre == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.infoContacto.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.infoContacto.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.direccion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.comuna == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione su comuna.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.asunto == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese el asunto por el cual desea hacer su consulta.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.mensaje == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un mensaje para conocer más de su consulta.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 200 // ms
    });
    this.$axios
      .post("contacto", {
        rut: this.infoContacto.rut,
        nombre: this.infoContacto.nombre,
        email: this.infoContacto.email,
        telefono: this.infoContacto.telefono,
        direccion: this.infoContacto.direccion,
        comuna: this.infoContacto.comuna,
        mensaje: this.infoContacto.mensaje,
        asunto: this.infoContacto.asunto
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente su mensaje.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.$router.push({ name: "Home" }).catch(err => {
          console.log(err);
        });
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        const mensaje = err.response.data.message;
        if (mensaje == "NOT_ALLOW") {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un problema, intentelo más tarde.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_RUT") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un rut válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_RUT") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un rut válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_NOMBRE") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un nombre válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_EMAIL") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un correo válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_TELEFONO") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un teléfono válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_DIRECCION") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar una dirección válida.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_COMUNA") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar una comuna válida.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_ASUNTO") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un asunto válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_MENSAJE") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un mensaje válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un problema, intentelo más tarde.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      });
  }
  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }

  private filterFnComuna(val: any, update: any, abort: any) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunaOptions = this.cityList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }
}
